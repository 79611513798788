import React from 'react';
import { makeStyles } from "@material-ui/core";
import { useSelector } from 'react-redux';
import { theme } from "../App";

function BoxLearnerComponent(props) {

    const classes = useStyles(props)();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isSmallView, setSmallView] = React.useState(false);


    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);

    return (
        <div className={classes.box}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 20, flexWrap: screenReducer.size <= 855 ? 'wrap' : 'initial' }}>
                <div style={{ width: '100%' }}>
                    <div className={classes.title}>
                        {props.iconTitle && props.iconTitle}
                        {props.title && <p style={{ margin: 0, color: theme.colorPrimary }}>{props.title}</p>}
                    </div>
                    <hr style={{ margin: 0, color: '#EDEEF0', border: '2px solid', borderRadius: 100 }} />
                </div>
                {props.action && props.action}
            </div>
            <div
                className={isSmallView ? classes.contentSmall : classes.content}
                style={props.styleContent ? (isSmallView ? props.styleContentSmall : props.styleContent) : {}}
            >
                {props.children}
            </div>
        </div>
    );
}

const useStyles = (props) => makeStyles(({

    box: {
        //margin: '50px auto',
        textAlign: 'left',
        borderRadius: 10,
        position: 'relative'
    },
    title: {
        textAlign: 'left',
        fontSize: 20,
        padding: '7px 0',
        display: 'flex',
        alignItems: 'center',
        '& > svg': {
            marginLeft: 10,
        },
        '& > p': {
            marginLeft: 10,
            fontWeight: 'bold',
        }
    },
    content: {
        margin: 0,
        //padding: 25,
        display: 'flex',
    },
    contentSmall: {
        margin: 0,
        display: 'flex',
        '& > div': {
            margin: 10
        }
    },
}));

export default BoxLearnerComponent;
