import React from 'react';
import { Box, Fade, IconButton, makeStyles } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import { theme } from "../../../../App";
import { LibraryResourceImage } from '../../../../Api';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useSelector } from 'react-redux';
import { Rating } from '@material-ui/lab';

function CardResource(props) {
    const classes = useStyles(theme)();
    const data = props.resource;
    const [load, setLoad] = React.useState(false);
    const [isSmallView, setSmallView] = React.useState(false);
    const screenReducer = useSelector(state => state.ScreenReducer);

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
        if ((screenReducer.screen === 'XS' || screenReducer.screen === 'SM')) {
            setLoad(false);
        }
    }, [screenReducer.screen]);

    return (
        <>
            <Box className={`${classes.pictureRoot}`} style={(screenReducer.size <= 395) ? { width: 'auto' } : {}}>
                {(!load) && <img src="/asset/images/preload_image.png" alt={'Image'} />}
                {(props.path && !isSmallView) && <img src={props.path} alt={'Image'} onLoad={() => { setLoad(true) }} />}
            </Box>
            <Box style={{ margin: 10 }}>
                <Typography className={classes.cardText} variant="subtitle2" component="p">
                    {data.title && data.title}
                </Typography>
                <Typography className={classes.cardText} style={{ fontSize: 12 }} variant="subtitle2" component="p">
                    {data.subTitle && data.subTitle}
                </Typography>
            </Box>
            <Box style={{ margin: 'auto 0 0 0' }}>
                <div className={classes.styleStats} >
                    <p className='view-root'><VisibilityIcon />{data.numberViews}</p>
                    <p className='rating-root'>
                        <Rating
                            name="hover-feedback"
                            value={data.average}
                            precision={1}
                            readOnly
                        />
                    </p>
                </div>
                <IconButton
                    className={classes.iconButton}>
                    <p className={classes.access}>Accéder</p>
                </IconButton>
            </Box>
        </>
    );
}

const useStyles = (theme) => makeStyles(({
    cardText: {
        margin: 0,
        fontSize: 15,
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': 2,
        lineHeight: '17px',
        letterSpacing: 'initial'
    },
    pictureRoot: {
        background: '#393939',
        width: 320,
        height: 160,
        borderRadius: 11,
        position: 'relative',
        overflow: 'hidden',
        margin: 10,
        '& img': {
            position: 'absolute',
            width: '120%',
            transform: 'translate(-50%,-50%)',
            top: '50%',
            left: '50%',
        },
        '&::before': {
            content: "' '",
            width: '100%',
            height: '100%',
            position: 'absolute',
            background: '#39393914',
            zIndex: 2
        }
    },
    cardHour: {
        width: 'max-content',
        fontSize: 12,
        alignItems: 'center',
        display: 'flex',
        gap: 5
    },
    cardStateIcon: {
        fontSize: 18,
        color: '#5F6577'
    },
    cardHourIcon: {
        fontSize: 18,
        color: '#5F6577'
    },
    iconButton: {
        padding: '0 10px',
        height: '100%',
        width: '100%',
        borderRadius: '0 0 12px 12px',
        background: '#F6F6F6'
    },
    access: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 14,
        fontWeight: 'bold',
        padding: 10,
        color: '#5F6577',
        margin: 0
    },
    styleStats: {
        display: 'flex',
        margin: 10,
        '& p.view-root': {
            display: 'flex',
            alignItems: 'center',
            width: 'fit-content',
            fontSize: 13,
            gap: 3,
            padding: '0 10px',
            borderRadius: '5px 0 0 5px',
            border: '2px solid rgba(0, 0, 0, 0.08)',
            margin: 0,
            '& svg': {
                width: 16,
            },
        },
        '& p.rating-root': {
            alignItems: 'center',
            width: 'fit-content',
            padding: '0 10px',
            borderRadius: '0 5px 5px 0',
            borderRight: '2px solid rgba(0, 0, 0, 0.08)',
            borderTop: '2px solid rgba(0, 0, 0, 0.08)',
            borderBottom: '2px solid rgba(0, 0, 0, 0.08)',
            margin: 0,
            display: 'flex',
            '& svg': {
                width: 16,
            },
            '& .MuiRating-root': {
                color: theme.colorPrimary,
                fontSize: 17,
            },
        },
    }
}));

export default CardResource;
