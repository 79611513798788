import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, makeStyles, Zoom } from "@material-ui/core";
import CardFormation from "./Component/CardFormation";
import Api, { learnerHomeFormationImageBanner } from "../../../Api";
import Fade from "@material-ui/core/Fade";
import { useDispatch, useSelector } from "react-redux";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import { getRoutePathname } from "../../../Config/Route";
import { theme } from "../../../App";
import moment from 'moment';
import EventBoxComponent from '../../../Component/EventBoxComponent';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { AuthenticationLogin } from '../../../Action/AuthenticationAction';
import BoxLearnerComponent from '../../../Component/BoxLearnerComponent';
import FeaturedResourceComponent from '../../../Component/FeaturedResourceComponent';
import CardResourceType from './Component/CardResourceType';
import ButtonComponent from '../../../Component/ButtonComponent';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import { driver } from 'driver.js';
import "driver.js/dist/driver.css";
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import { SnackbarOpen } from '../../../Action/SnackbarAction';
import ContentViewLearnerComponent from '../../../Component/ContentViewLearnerComponent';
import LearnerImgSvgComponent from '../../../Component/LearnerImgSvgComponent';
import PaginationComponent from '../../../Component/PaginationComponent';
import CarouselViewComponent from '../../../Component/CarouselViewComponent';

function Home(props) {
    const classes = useStyles(theme)();
    const dispatch = useDispatch();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const memoryReducer = useSelector(state => state.MemoryReducer);
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [formationsLeaner, setFormationsLeaner] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [pendingInfo, setPendingInfo] = React.useState(true);
    const [show, setShow] = React.useState(true);
    const [isSmallView, setSmallView] = React.useState(false);
    const [cardSmall, setCardSmall] = React.useState({ grid: 4, width: 'auto' });
    const [countSpaces, setCountSpaces] = React.useState({
        in_progress_formations: '',
        pending_formations: ''
    });
    const [timeProcessing, setTimeProcessing] = React.useState();
    const [isEventBoxShow, setIsEventBoxShow] = React.useState(false);
    const [stateEventBoxContent, setStateEventBoxContent] = React.useState();
    const currentYear = moment().year();
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [countDataFormation, setCountDataFormation] = React.useState({
        countDataPending: '',
        countDataInProgress: ''
    });
    const [libraryResourceTypes, setLibraryResourceTypes] = React.useState([]);
    const [showBoxChrome, setShowBoxChrome] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);
    const [showTuto, setShowTuto] = React.useState(false);
    const [dataStepTuto, setDataStepTuto] = React.useState({});
    const [isStartTuto, setIsStartTuto] = React.useState(false);

    const goRegistration = (registrationId, organismId) => {
        if (parseInt(organismId) === authenticationReducer.organism) {
            setTimeout(() => {
                props.history.push(getRoutePathname('learner_registration', { id: registrationId }));
            }, 600);
        }
        else {
            Api.post({
                route: 'authentication_login',
                data: {
                    email: authenticationReducer.email,
                    password: 'learner',
                    isAdmin: false,
                    organism: parseInt(organismId),
                    isLoginAs: authenticationReducer.isLoginAs
                }
            }, (response) => {
                dispatch(AuthenticationLogin(response.data));
                setTimeout(() => {
                    props.history.push(getRoutePathname('learner_registration', { id: registrationId }));
                }, 1500);
            });
        }
    };

    const changeStateEventBoxContent = (times) => {
        if (!times.timeYearNext && times.timeYearCurrent) {
            if ((times.totalFormationDurationCurrent < times.timeYearCurrent)) {
                setStateEventBoxContent(1);
            }
            else {
                setStateEventBoxContent(theme.id === 1 ? 2 : 0);
            }
        }
        else if (times.timeYearNext) {
            if ((times.totalFormationDurationNext < times.timeYearNext) &&
                (times.totalFormationDurationCurrent < times.timeYearCurrent)) {
                setStateEventBoxContent(1);
            }
            else if ((times.totalFormationDurationNext >= times.timeYearNext) &&
                (times.totalFormationDurationCurrent >= times.timeYearCurrent)) {
                setStateEventBoxContent(theme.id === 1 ? 2 : 0);
            }
            else {
                setStateEventBoxContent(3);
            }
        }
    };

    const addTutorial = (dataStepTuto) => {
        setDataStepTuto(dataStepTuto);
        Api.post({
            route: 'learner_tutorial',
            data: {
                tutorial: {
                    home: dataStepTuto
                }
            }
        }, (response) => {
            if (response.status === 200) {

            }
            else if (response.status === 400) {
                dispatch(
                    SnackbarOpen({
                        text: 'Erreur',
                        variant: 'error',
                    })
                );
            }
            else {
                dispatch(
                    SnackbarOpen({
                        text: 'Une erreur inattendue s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    }

    const [driverObj, setDriverObj] = React.useState(null);
    const layout = document.querySelector('#layout');
    const startTour = () => {
        const updateTutorialState = (currentStep, totalSteps, isStart) => {
            if (dataStepTuto.isStartTuto) {
                let data = {
                    total: totalSteps,
                    currentStep: currentStep ? currentStep + 1 : totalSteps,
                    isStartTuto: isStart
                }
                addTutorial(data);
                setDataStepTuto(data);
            }
        };
        if (layout) layout.style.overflow = 'hidden';
        const steps = [
            {
                element: '#stepPendingFormations',
                popover: {
                    title: 'Formations en attente',
                    description: `Les formations listées ci-dessous sont actuellement en attente de validation. 
                    Une fois que votre inscription sera validée, vous recevrez une notification avec les instructions 
                    pour y accéder. Veuillez attendre la confirmation et rester disponible pour toute communication 
                    supplémentaire requise pour finaliser votre inscription.`,
                    side: "right",
                    align: 'start'
                }
            },
            {
                element: '#stepInProgressFormations .stepCard',
                popover: {
                    title: 'Formations en cours',
                    description: `Voici les formations qui sont disponible (ou déjà commencées). 
                    Vous pouvez reprendre chaque module à tout moment en cliquant sur <b>Continuer</b>.
                    N'oubliez pas de suivre les instructions et de compléter toutes les activités pour 
                    finaliser votre formation.`,
                    side: "right",
                    align: 'start'
                }
            },
            {
                element: '#setpAppBarCatalog',
                popover: {
                    title: 'Accès aux catalogues',
                    description: `Découvrez notre catalogue complet de formations disponibles. 
                    Parcourez les différentes catégories pour trouver des formations adaptées à 
                    vos besoins et intérêts. Cliquez <b>ici</b> pour en savoir plus sur chaque 
                    formation et inscrivez-vous pour commencer votre parcours d'apprentissage.`,
                    side: "right",
                    align: 'start'
                }
            },
            {
                element: '#stepLibraryResource',
                popover: {
                    title: 'Bibliothèque de ressources',
                    description: `Accédez à une vaste bibliothèque de ressources pédagogiques, 
                    incluant des documents, des vidéos, des articles et des guides. 
                    Utilisez ces ressources pour approfondir vos connaissances et compléter votre apprentissage. 
                    Cliquez sur Consulter pour explorer le contenu disponible et enrichir votre formation.`,
                    side: "right",
                    align: 'start'
                }
            },
            {
                element: '#stepFeaturedResources',
                popover: {
                    title: 'Ressources à la une',
                    description: `Découvrez les ressources les plus populaires et recommandées par nos experts. 
                    Consultez les documents, vidéos et articles mis en avant pour enrichir votre apprentissage et 
                    rester à jour avec les dernières tendances et connaissances.`,
                    side: "right",
                    align: 'start'
                }
            },
            {
                element: '#setpAppBarLibraryResource',
                popover: {
                    title: 'Accès aux ressoures',
                    description: `Vous pouvez aussi accéder à toutes les ressources en cliquant <b>ici</b>`,
                    side: "right",
                    align: 'start'
                }
            },
            {
                element: '#setpAppBarCommunity',
                popover: {
                    title: 'Accès aux forum',
                    description: `Rejoignez la communauté et participez aux discussions sur nos forums. 
                    Posez vos questions, partagez vos expériences et échangez avec d'autres apprenants. 
                    Cliquez <b>ici</b> pour commencer à interagir et enrichir votre parcours 
                    de formation grâce à l'expérience des autres membres.`,
                    side: "right",
                    align: 'start'
                }
            },
            {
                element: '#stepYourAccount',
                popover: {
                    title: 'Votre compte',
                    description: `Gérez facilement votre compte et vos informations personnelles.
                     Cliquez sur <b>Mes informations</b> pour mettre à jour vos données personnelles et vos préférences. 
                     Utilisez <b>Mes documents</b> pour accéder et organiser vos documents liés à vos formations et certifications.`,
                    side: "right",
                    align: 'start'
                }
            },
        ]

        const validSteps = steps.filter((step) => document.querySelector(step.element));

        const driverInstance = driver({
            showProgress: true,
            progressText: '{{current}} sur {{total}}',
            doneBtnText: 'Terminé',
            closeBtnText: 'Fermer',
            nextBtnText: 'Suivant',
            prevBtnText: 'Précédent',
            onNextClick: (element) => {
                driverInstance.moveNext();
                const { steps } = driverInstance.getConfig();
                const currentStep = driverInstance.getActiveIndex();
                if (element.id != 'stepYourAccount') {
                    updateTutorialState(currentStep, steps.length, true);
                }
            },
            onPrevClick: () => {
                driverInstance.movePrevious();
            },
            onCloseClick: () => {
                const { steps } = driverInstance.getConfig();
                const currentStep = driverInstance.getActiveIndex();
                updateTutorialState(currentStep, steps.length, false);
                if (layout) layout.style.overflow = 'hidden scroll';
                setShowTuto(false);
                setIsOpen(false);
                setIsStartTuto(false);
                driverInstance.destroy();
            },
            onDestroyStarted: () => {
                const { steps } = driverInstance.getConfig();
                const currentStep = driverInstance.getActiveIndex();
                updateTutorialState(currentStep, steps.length, false);
                if (layout) layout.style.overflow = 'hidden scroll';
                setShowTuto(false);
                setIsOpen(false);
                setIsStartTuto(false);
                driverInstance.destroy();
            },
            steps: validSteps,
        });
        driverInstance.drive();
        setDriverObj(driverInstance);
    };

    const cardSpace = (countSpaces) => {
        const isSpaceText = (authenticationReducer.isInstituOrganism || countSpaces === 1 || countSpaces === 2);
        return (
            <div style={{ display: 'flex', gridColumn: `span ${countSpaces}` }} >
                <Fade in={true} {...{ timeout: 500 }}>
                    <Box className={classes.cardAccesRoot}>
                        <Box style={{ textAlign: 'center', margin: isSpaceText ? 'auto' : '', zIndex: 1, position: 'relative' }}>
                            <h1 style={{ color: theme.colorPrimary, margin: 5 }}>{isSpaceText ? 'Formations' : <span style={{ fontSize: 40 }}>Catalogue de formations</span>}</h1>
                            {isSpaceText ? <p style={{ margin: 0, fontSize: countSpaces === 1 ? 13 : '' }}>
                                Accédez à des formations certifiées, conçues par des experts.
                                Enrichissez vos compétences à votre rythme avec des cours adaptés à votre spécialité,
                                accessibles à tout moment.
                            </p> : <p style={{ margin: 0 }}>
                                Explorez une sélection complète de formations en ligne adaptées à toutes les spécialités médicales.
                                Chaque programme est pensé pour enrichir vos compétences, que vous soyez en quête de perfectionnement
                                ou de nouvelles connaissances. Conçus par des experts, nos cours certifiés sont accessibles à tout moment,
                                pour un apprentissage flexible et personnalisé. Développez votre expertise et faites évoluer votre pratique médicale.
                            </p>}
                        </Box>
                        {!authenticationReducer.isInstituOrganism && <p className={classes.textLink} style={{ margin: countSpaces === 1 ? 0 : 10, zIndex: 1, position: 'relative' }}
                            onClick={() => { props.history.push(getRoutePathname('learner_catalog')) }}
                        ><span style={{ padding: '5px 50px' }}>Accéder</span></p>}
                        {(countSpaces != 1) && <LearnerImgSvgComponent style={{ width: '100%' }} />}
                    </Box>
                </Fade>
            </div>
        )
    }

    React.useEffect(() => {
        if (driverObj) {
            const unblock = props.history.block(() => {
                driverObj.destroy();
                layout.style.overflow = 'hidden scroll';
                unblock();
            });

            const NewdriverObj = new MutationObserver(() => {
                driverObj.refresh();
            });

            NewdriverObj.observe(document.body, {
                childList: true,
                subtree: true,
                attributes: true,
            });

            return unblock;
        }
    }, [driverObj]);

    React.useEffect(() => {

        //----- POPUP CHROME --------//
        let userAgent = '';
        let lastShowBoxChrome = localStorage.getItem('lastShowBoxChrome');
        if (window && window.navigator && window.navigator.userAgent) {
            userAgent = window.navigator.userAgent.toLowerCase();
        }
        if (!(/chrome|crios/i.test(userAgent))) {
            if (moment().subtract(1, 'days').isBefore(lastShowBoxChrome)) {
                setShowBoxChrome(false);
            }
            else {
                setShowBoxChrome(true);
                localStorage.setItem('lastShowBoxChrome', moment().format('YYYY-MM-DD'));
            }
        }
        //----- POPUP CHROME --------//

        Api.get({
            route: 'learner_home_time_processing'
        },
            (response) => {
                setTimeProcessing(response.data);
                changeStateEventBoxContent(response.data);
            });

        let lastConnection = localStorage.getItem('lastConnection');

        if (moment().subtract(3, 'days').isBefore(lastConnection)) {
            setIsEventBoxShow(false);
        }
        else {
            setIsEventBoxShow(true);
            localStorage.setItem('lastConnection', moment().format('YYYY-MM-DD'));
        }
    }, []);

    const [loadingResource, setLoadingResource] = React.useState(true);
    const [loadingHome, setLoadingHome] = React.useState(true);
    React.useEffect(() => {
        dispatch(LinearProgressMainLayoutActivate());
        // Redirect auto catalog
        if (memoryReducer.registerFormation) {
            props.history.push(getRoutePathname('learner_catalog'));
        }
        else {
            Api.get({ route: 'learner_home_info' },
                (response) => {
                    setLoadingHome(false);
                    setFormationsLeaner(response.data.data);
                    setCountDataFormation({
                        countDataPending: response.data.countDataPending,
                        countDataInProgress: response.data.countDataInProgress
                    });
                    const isTutorial = (response.data.tutorial && response.data.tutorial.length != 0) ? response.data.tutorial.isStartTuto : true;
                    setShowTuto(isTutorial ? true : false);
                    setDataStepTuto(
                        (response.data.tutorial && response.data.tutorial.length != 0) ?
                            response.data.tutorial : {
                                total: 0,
                                currentStep: 0,
                                isStartTuto: true
                            }
                    )
                    setPendingInfo(false);
                });

            Api.get({
                route: 'learner_home_library_resource_types',
                data: { organism: authenticationReducer.organism, formationGroup: authenticationReducer.formationGroup }
            },
                (response) => {
                    setLoadingResource(false);
                    if (response && response.data) {
                        setLibraryResourceTypes(response.data);
                    }
                });
        }
    }, []);

    const viewList = (data) => {
        return (
            <Fade in={true} {...{ timeout: 500 }}>
                <CardResourceType
                    key={data.value}
                    libraryResourceType={data}
                    onClick={() => {
                        props.history.push(getRoutePathname('learner_resource_type', { id: data.value }))
                    }}
                />
            </Fade>
        )
    }

    const countEmptySpaces = (data, key, grid) => {
        const formationsLength = Object.values(data).reduce((total, item) => {
            return item[key] ? total + item[key].length : total;
        }, 0);

        const remainder = formationsLength % grid;
        return remainder === 0 ? 0 : grid - remainder;
    };

    const viewCard = (item, index, activeStep) => {
        return (
            <CardFormation
                text={item.availableAt ? (
                    <span>
                        Contenu disponible le <br />
                        {moment(item.availableAt).format('ll')}
                    </span>
                ) : 'Voir la formation'}
                availableAt={item.availableAt}
                typeView={'carousel'}
                show={show}
                hours={item.hours}
                organism={item.organismId}
                image={learnerHomeFormationImageBanner + item.image}
                textSession={item.textSession === '' ? 'Aucune durée' : item.textSession}
                title={item.title}
                state={item.state}
                onClick={() => (index != activeStep || item.availableAt) ? {} : goRegistration(item.registration, item.organismId)}
                financeTypeName={item.financeTypeName}
                disabled={index != activeStep}
                type={item.type}
            />
        )
    }

    const getInData = (data, name) => {
        if (!data || typeof data !== "object") {
            console.error("Invalid data format:", data);
            return [];
        }

        return Object.entries(data)
            .filter(([_, value]) => value[name])
            .flatMap(([key, value]) =>
                value[name].map((formation) => ({
                    ...formation,
                    organismId: parseInt(key),
                }))
            );
    };

    React.useEffect(() => {
        setCountSpaces({
            in_progress_formations: countEmptySpaces(formationsLeaner, 'in_progress_formations', cardSmall.grid),
            pending_formations: countEmptySpaces(formationsLeaner, 'pending_formations', cardSmall.grid)
        })
    }, [formationsLeaner, cardSmall]);

    React.useEffect(() => {
        if (!(loadingResource && loadingHome)) {
            setLoading(false);
            dispatch(LinearProgressMainLayoutDeactivate());
        }
    }, [loadingResource, loadingHome]);

    const [state, setState] = React.useState(false);

    React.useEffect(() => {
        setBreadcrumbs({
            currentPage: 'Tableau de bord',
            title: <p style={{ color: '#FFF', margin: screenReducer.size >= 540 ? '10px 0 40px 0' : '10px 0 0px 0' }}>
                <span style={screenReducer.size >= 540 ? { fontSize: 90, display: 'block', fontWeight: 'bold' } : { fontSize: 40, display: 'block', fontWeight: 'bold', textAlign: 'center' }}>Bienvenue</span>
                <span style={{ fontSize: 15, display: 'block', maxWidth: 700, textAlign: screenReducer.size >= 540 ? 'left' : 'center' }}>La plateforme de formation en ligne dédiée aux médecins. Accédez à des cours certifiés, conçus par des experts, pour perfectionner vos compétences et rester à jour avec les dernières avancées</span>
            </p>,
            html: <FeaturedResourceComponent organism={authenticationReducer.organism} formationGroup={authenticationReducer.formationGroup} history={props.history} />
        });
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));

        setCardSmall((prev) => {
            const values = {
                1: { width: 265, grid: 1 },
                2: { width: 548, grid: 2 },
                3: { width: 832, grid: 3 },
                default: { width: 'auto', grid: 4 }
            };

            const conditions = [
                { condition: screenReducer.size <= 610, type: 1 },
                { condition: screenReducer.size <= 925, type: 2 },
                { condition: screenReducer.size <= 1202, type: 3 },
            ];

            const valueType = conditions.find(cond => cond.condition)?.type || 'default';
            return {
                grid: values[valueType].grid,
                width: values[valueType].width
            };
        })
    }, [screenReducer, state]);

    return (
        <ContentViewLearnerComponent loading={loading} breadcrumbs={breadcrumbs} {...props}>
            {/* ----- POPUP CHROME -------- */}
            {
                showBoxChrome &&
                <EventBoxComponent
                    content={
                        <>
                            <p>Pour une expérience optimale, utilisez Google Chrome comme navigateur web.</p>
                            <p>Chrome offre une stabilité et une compatibilité idéales pour nos formations.</p>
                            <p>Pour télécharger Chrome gratuitement, <a style={{ color: theme.colorPrimary }} href="https://www.google.com/intl/fr/chrome/?brand=FCBR&ds_kid=43700075934932907&gad_source=1&gclid=Cj0KCQiA84CvBhCaARIsAMkAvkLrdrTMv0r8SjmxlqHGUppSLlWgfR_dsKilGM44Vj3n4xdGul3UFMgaAmFdEALw_wcB&gclsrc=aw.ds" target="_blank">cliquez ici</a>.</p>
                            <p>Merci pour votre coopération. Besoin d'aide ? Contactez-nous.</p>
                            <p>L'équipe {theme.name}</p>
                        </>
                    }
                    open={showBoxChrome}
                    labelButton={'Compris'}
                    onClickButton={() => {
                        setShowBoxChrome(false);
                    }}
                    icon={<img src="/asset/images/chrome-logo.svg" alt="logo chrome" />}
                    withoutFooter={true}
                    rightDirection={true}
                />
            }

            {isEventBoxShow &&
                (((timeProcessing && timeProcessing.timeYearCurrent) || (timeProcessing && timeProcessing.timeYearNext)) && stateEventBoxContent > 0) &&
                <EventBoxComponent
                    content={
                        <>
                            {stateEventBoxContent === 1 &&
                                <>
                                    {timeProcessing.totalFormationDurationCurrent > 0 && <p>Vous avez consommé {timeProcessing.totalFormationDurationCurrent}h de formation DPC.</p>}
                                    <p>
                                        Nous estimons votre compte formation à {(timeProcessing.timeYearCurrent - timeProcessing.totalFormationDurationCurrent)}h.<br />
                                        Ce qui vous donne droit à encore vous former et percevoir {timeProcessing.maxRemunerationCurrent / timeProcessing.timeYearCurrent * (timeProcessing.timeYearCurrent - timeProcessing.totalFormationDurationCurrent)}€
                                        de rémunération sur l'année {currentYear}.
                                    </p>
                                </>
                            }

                            {stateEventBoxContent === 2 &&
                                <>
                                    <p>Félicitation vous avez consommé votre budget DPC.</p>
                                    <p>Mais ne vous inquiétez pas !<br /> Nous pouvons vous accompagner sur des formation CPF ou FIFPL.</p>
                                </>
                            }

                            {stateEventBoxContent === 3 &&
                                <>
                                    <p>Félicitation vous avez complété votre budget DPC {currentYear}. <br />Mais bonne nouvelle, des sessions sont disponible pour {currentYear + 1}.</p>
                                    <p>
                                        Nous estimons votre compte formation à {(timeProcessing.timeYearNext - timeProcessing.totalFormationDurationNext)}h.<br />
                                        Ce qui vous donne droit à encore vous former et percevoir {timeProcessing.maxRemunerationNext / timeProcessing.timeYearNext * (timeProcessing.timeYearNext - timeProcessing.totalFormationDurationNext)}€
                                        de rémunération sur l'année {currentYear + 1}.
                                    </p>
                                </>
                            }
                            <p>Vous pouvez accéder directement à notre catalogue en cliquant ci-dessous.</p>
                        </>}
                    open={isEventBoxShow}
                    labelButton={'Catalogue'}
                    onClickButton={() => {
                        props.history.push(getRoutePathname('learner_catalog'))
                    }}
                    icon={<NotificationsNoneIcon />}
                />
            }

            <BoxLearnerComponent
                title={'Votre carnet de formations'}
                styleContent={{ flexDirection: 'column', alignItems: 'center' }}
                styleContentSmall={{ flexDirection: 'column', alignItems: 'center' }}
            >
                {(countDataFormation.countDataPending > 0) &&
                    <div id={'stepPendingFormations'} style={{ width: '100%' }}>
                        <p style={{ textAlign: 'left', width: '96%', fontWeight: 'bold', display: 'flex', gap: 5, alignItems: 'center' }}><HourglassEmptyIcon /> Formations en attente</p>
                        {screenReducer.size >= 611 ?
                            <div style={{ display: 'grid', gridTemplateColumns: `repeat(${cardSmall.grid}, 1fr)`, gap: 20, margin: 'auto', width: cardSmall.width }}>
                                {Object.keys(formationsLeaner).map((organismId) =>
                                    (formationsLeaner[organismId] && formationsLeaner[organismId]['pending_formations']) &&
                                    formationsLeaner[organismId]['pending_formations'].map((formationLeaner, index) =>
                                        <div className={'stepCard'} style={{ display: 'flex' }} key={index}>
                                            <CardFormation
                                                text={formationLeaner.availableAt ? <span>Contenu disponible le <br /> {moment(formationLeaner.availableAt).format('ll')}</span> : 'Voir la formation'}
                                                availableAt={formationLeaner.availableAt}
                                                show={show}
                                                hours={formationLeaner.hours}
                                                organism={organismId}
                                                image={learnerHomeFormationImageBanner + formationLeaner.image}
                                                textSession={formationLeaner.textSession == "" ? 'Aucune durée' : formationLeaner.textSession}
                                                title={formationLeaner.title}
                                                state={formationLeaner.state}
                                                financeTypeName={formationLeaner.financeTypeName}
                                                onClick={() => formationLeaner.availableAt ? {} : goRegistration(formationLeaner.registration, organismId)}
                                                type={formationLeaner.type}
                                            />
                                        </div>
                                    )
                                )}
                                {(countSpaces.pending_formations != 0) && cardSpace(countSpaces.pending_formations)}
                            </div> :
                            <CarouselViewComponent
                                data={getInData(formationsLeaner, 'pending_formations')}
                                view={viewCard}
                                {...props}
                            />}
                    </div>
                }

                <div id={'stepInProgressFormations'} style={{ width: '100%' }}>
                    {(countDataFormation.countDataInProgress > 0) && <p style={{ textAlign: 'left', width: '96%', fontWeight: 'bold', display: 'flex', gap: 5, alignItems: 'center' }}><TimelapseIcon /> Vos formations en cours</p>}
                    {screenReducer.size >= 611 ?
                        <div style={{ display: 'grid', gridTemplateColumns: `repeat(${cardSmall.grid}, 1fr)`, gap: 20, margin: 'auto', width: cardSmall.width }}>
                            {Object.keys(formationsLeaner).map((organismId) => {
                                return (formationsLeaner[organismId] && formationsLeaner[organismId]['in_progress_formations'])
                                    && formationsLeaner[organismId]['in_progress_formations'].map((formationLeaner, index) =>
                                        <div className={'stepCard'} style={{ display: 'flex' }} key={index}>
                                            <CardFormation
                                                text={formationLeaner.availableAt ? <span>Contenu disponible le <br /> {moment(formationLeaner.availableAt).format('ll')}</span> : 'Voir la formation'}
                                                availableAt={formationLeaner.availableAt}
                                                show={show}
                                                hours={formationLeaner.hours}
                                                organism={organismId}
                                                image={learnerHomeFormationImageBanner + formationLeaner.image}
                                                textSession={formationLeaner.textSession == "" ? 'Aucune durée' : formationLeaner.textSession}
                                                title={formationLeaner.title}
                                                state={formationLeaner.state}
                                                financeTypeName={formationLeaner.financeTypeName}
                                                onClick={() => formationLeaner.availableAt ? {} : goRegistration(formationLeaner.registration, organismId)}
                                                type={formationLeaner.type}
                                            />
                                        </div>
                                    )
                            })}
                            {(countSpaces.in_progress_formations != 0) && cardSpace(countSpaces.in_progress_formations)}
                        </div> :
                        <CarouselViewComponent
                            data={getInData(formationsLeaner, 'in_progress_formations')}
                            view={viewCard}
                            errorText={
                                <>
                                    {pendingInfo && <p>Chargement de vos formations ...</p>}
                                    {!pendingInfo && (countDataFormation.countDataPending <= 0 && countDataFormation.countDataInProgress <= 0) && <p>Vous n'avez pas de formation disponible</p>}
                                </>
                            }
                            {...props}
                        />}
                </div>
                {(pendingInfo && (screenReducer.size >= 611)) && < p > Chargement de vos formations ...</p>}
                {!pendingInfo && (countDataFormation.countDataPending <= 0 && countDataFormation.countDataInProgress <= 0) && (screenReducer.size >= 611) && <p>Vous n'avez pas de formation disponible</p>}
            </BoxLearnerComponent>

            {
                (libraryResourceTypes && libraryResourceTypes.length > 0) &&
                <Box id={'stepLibraryResource'} className={classes.cardRoot} >
                    <BoxLearnerComponent
                        title={'Bibliothèque de ressources'}
                        styleContent={{ flexDirection: 'column' }}
                        styleContentSmall={{ flexDirection: 'column' }}
                        action={
                            <p id={'stepAllLibraryResource'}
                                className={classes.textLink}
                                onClick={() => { props.history.push(getRoutePathname('learner_library_resource')) }}
                            >
                                <span>Voir toutes les ressources</span>
                            </p>
                        }
                    >
                        <div className={classes.PaginationResource} style={{
                            alignItems: 'center',
                            display: 'flex', gap: 10, flexWrap: 'wrap',
                            width: screenReducer.size <= 420 ? 'auto' : screenReducer.size <= 800 ? 333 : screenReducer.size <= 1186 ? 668 : 1002, margin: '22px auto auto'
                        }}>
                            <PaginationComponent
                                dataList={libraryResourceTypes}
                                pageNumber={screenReducer.size >= 1186 ? 3 : 4}
                                viewList={viewList}
                                error={'Aucune ressouses'}
                            />
                        </div>
                    </BoxLearnerComponent>
                </Box>
            }

            {
                (!showTuto && !isStartTuto) &&
                <Button className={isSmallView ? classes.mobilbuttonTuto : classes.buttonTuto} style={{ background: '#5E6E82' }} onClick={() => { setShowTuto(true); setIsOpen(false); }} >
                    <p><span>Lancer le didacticiel</span></p> <LiveHelpIcon style={{ fontSize: 17 }} />
                </Button>
            }
            <Dialog className={classes.dialog} open={showTuto} onClose={() => { setShowTuto(false); if (dataStepTuto.isStartTuto) { addTutorial({ total: 0, currentStep: 0, isStartTuto: false }) } }} maxWidth={'xl'} TransitionComponent={Transition}>
                <DialogContent style={{ color: '#5F6577' }}>
                    <h1 style={{ margin: 0, color: theme.colorPrimary }}>Bienvenue sur votre <br />Espace apprenant</h1>
                    <hr style={{ width: '90%' }} />
                    <p style={{ margin: 0 }}>
                        <span>Souhaitez-vous suivre un court didacticiel pour découvrir toutes les fonctionnalités et astuces ?</span><br /><br />
                        <span>Cela ne prendra que quelques minutes !</span>
                    </p>
                </DialogContent>
                <DialogActions style={{ width: 'fit-content', margin: 'auto' }}>
                    <ButtonComponent color={'#5E6E82'} label={'Passer'} onClick={() => {
                        setShowTuto(false);
                        if (dataStepTuto.isStartTuto) {
                            addTutorial({
                                total: 0,
                                currentStep: 0,
                                isStartTuto: false
                            });
                        }
                    }} />
                    <ButtonComponent color={theme.colorPrimary} label={'Commencer le didacticiel'} onClick={() => {
                        startTour();
                        setIsStartTuto(true);
                        setShowTuto(false);
                    }} />
                </DialogActions>
            </Dialog>
        </ContentViewLearnerComponent >
    );
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Zoom ref={ref} {...props} />
});

const useStyles = (theme) => makeStyles(({
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        transform: 'translateY(50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div': {
            margin: 'auto',
            width: '15% !important'
        }
    },
    btn: {
        height: 55,
        width: 220,
        fontSize: 15,
        textAlign: 'center',
        padding: 14,
        cursor: 'pointer',
        boxShadow: 'none',
        border: '1px solid #DADADA',
        margin: '20px 5px'
    },
    btnIcon: {
        verticalAlign: 'middle'
    },
    btnText: {
        verticalAlign: 'middle',
        marginLeft: 10
    },
    textLink: {
        width: 'fit-content',
        cursor: 'pointer',
        fontWeight: 'bold',
        margin: 'auto',
        background: theme.colorPrimary,
        color: '#FFF',
        padding: 5,
        borderRadius: 10,
        transition: 'all .4s !important',
        '& span': {
            borderRadius: 6,
            padding: '5px 25px',
            border: '3px solid #FFF',
            display: 'block',
            width: 'max-content',
            fontSize: 13
        },
        '&:hover': {
            transform: 'scale(1.03)'
        }
    },
    dialog: {
        '& .MuiDialog-paper': {
            maxWidth: 450
        },
        '& .MuiDialog-paper, .MuiDialogContent-root': {
            overflow: 'initial',
            textAlign: 'center',
            padding: 15
        },
        '& .MuiDialogContent-root:first-child': {
            paddingTop: 'initial'
        }
    },
    cardRoot: {
        border: '2px solid #E6E9F0',
        borderRadius: 17,
        padding: 5,
        background: '#FFF',
        margin: '50px auto',
        '& >div': {
            width: '95%',
            margin: '20px auto'
        }
    },
    buttonTuto: {
        position: 'absolute',
        bottom: 25,
        right: 15,
        width: 'max-content',
        color: '#FFF',
        opacity: .5,
        fontSize: 11,
        minWidth: 'initial',
        transition: 'all .4s',
        '& p': {
            width: 0,
            overflow: 'hidden',
            margin: 0,
            transition: 'all .4s',
            '& span': {
                width: 'max-content',
                display: 'block',
                color: '#FFF'
            }
        },
        '&:hover': {
            opacity: '1 !important',
            '& p': {
                width: '133px',
            }
        }
    },
    mobilbuttonTuto: {
        position: 'fixed',
        bottom: 30,
        right: 15,
        width: 'max-content',
        color: '#FFF',
        opacity: .5,
        fontSize: 11,
        minWidth: 'initial',
        zIndex: 20,
        transition: 'all .4s',
        '& p': {
            width: 0,
            overflow: 'hidden',
            margin: 0,
            transition: 'all .4s',
            '& span': {
                width: 'max-content',
                display: 'block',
                color: '#FFF'
            }
        },
        '&:hover': {
            opacity: '1 !important',
            '& p': {
                width: '133px',
            }
        }
    },
    cardAccesRoot: {
        border: '2px solid #E6E9F0',
        borderRadius: 17,
        display: 'flex',
        gap: 10,
        padding: 40,
        width: '100%',
        background: '#FFF',
        alignItems: 'center',
        transition: 'all .4s !important',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        overflow: 'hidden'
    },
    PaginationResource: {
        '& .MuiPagination-root': {
            margin: 'auto !important'
        }
    }
}));

export default Home;
