import React from 'react';
import { Box, IconButton, Drawer as DrawerMUI, Tooltip, LinearProgress, Zoom, Button } from "@material-ui/core";
import UseAnimations from "react-useanimations";
import arrowDown from "react-useanimations/lib/arrowDown";
import alertTriangle from "react-useanimations/lib/alertTriangle";
import { useDispatch, useSelector } from "react-redux";
import { getRoutePathname } from "../../../Config/Route";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import Api, { learnerHomeFormationImageBanner, resource } from "../../../Api";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import DateRangeIcon from '@material-ui/icons/DateRange';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import moment from "moment";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { theme } from "../../../App";
import MediaLinkComponent from "../../../Component/MediaLinkComponent";
import Countdown from "./Component/Countdown";
import ButtonComponent from "../../../Component/ButtonComponent";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import Footer from '../../../Layout/LearnerLayoutV2/Component/Footer';
import { driver } from 'driver.js';
import "driver.js/dist/driver.css";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import CardComponent from './Component/CardComponent';
import { MediaShow } from '../../../Action/MediaAction';
import NotesIcon from '@material-ui/icons/Notes';
import ContentViewLearnerComponent from '../../../Component/ContentViewLearnerComponent';
import VirtualClassroomComponent from './Component/VirtualClassroomComponent';
import CardResourceComponent from './Component/CardResourceComponent';

const Title = (props) => {
    const classes = useStyles(theme, props.isSmallDrawer)();
    const screenReducer = useSelector(state => state.ScreenReducer);
    return (
        <Box className={classes.headerRoot}>
            <h1 className={props.isSmallView ? classes.mobilTitleRoot : classes.titleRoot} style={{ fontSize: screenReducer.size <= 1440 ? 25 : 30 }}>{props.registration.formation.name}</h1>
        </Box>
    )
}

function Registration(props) {
    const dispatch = useDispatch();
    const [isSmallDrawer, setIsSmallDrawer] = React.useState(false);
    const classes = useStyles(theme, isSmallDrawer)();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [loading, setLoading] = React.useState(true);
    const [show, setShow] = React.useState(true);
    const [isSmallView, setSmallView] = React.useState(false);
    const [isSmallViewLoad, setSmallViewLoad] = React.useState(false);
    const [registration, setRegistration] = React.useState({ formation: {}, chapters: [], resourceGroups: [] });
    const [countdownFinish, setCountdownFinish] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [totalDuration, setTotalDuration] = React.useState(0);
    const [seconds, setSeconds] = React.useState(0);
    const [isOpen, setIsOpen] = React.useState(false);
    const [dataStepTuto, setDataStepTuto] = React.useState({});
    const [isStartTuto, setIsStartTuto] = React.useState(false);
    const [isScreen, setIsScreen] = React.useState(false);

    const goCourse = (course) => {
        setShow(false);
        setTimeout(() => {
            props.history.push({
                pathname: getRoutePathname('learner_registration_course', { registration: props.match.params.id, course: course.id }),
                state: { courseType: course.type }
            });
        }, 800);
    };
    const goSurveySatisfaction = () => {
        setShow(false);
        setTimeout(() => {
            props.history.push({
                pathname: getRoutePathname('learner_registration_survey_satisfaction', { registration: props.match.params.id })
            });
        }, 800);
    };
    const goSurveyPrePost = (type) => {
        setShow(false);
        setTimeout(() => {
            props.history.push({
                pathname: getRoutePathname('learner_registration_survey_pre_post', { registration: props.match.params.id, type: type })
            });
        }, 800);
    };

    const goSurveyEpp = (type) => {
        setShow(false);
        setTimeout(() => {
            props.history.push({
                pathname: getRoutePathname('learner_registration_survey_epp', { registration: props.match.params.id, type: type })
            });
        }, 800);
    };

    const stayTime = (duration, timeSpent, type) => {
        const stayTimeSeconds = duration - timeSpent;
        const hours = Math.floor(stayTimeSeconds / 3600);
        const minutes = Math.floor((type === 1 ? stayTimeSeconds : (stayTimeSeconds % 3600)) / 60);
        const remainingSeconds = stayTimeSeconds % 60;

        const time = type === 1 ? minutes + ' min ' + remainingSeconds + 's' : hours + ' heures ' + minutes + 'm ' + remainingSeconds + 's restant';

        return (
            ((timeSpent !== 0) && (duration !== 0)) &&
            <span className={classes.timerRoot} style={{ background: ((timeSpent === duration) || (stayTimeSeconds <= 0)) ? '#6FD08C' : theme.colorPrimary }}>
                <AccessTimeIcon style={{ fontSize: 16 }} /> {((timeSpent === duration) || (stayTimeSeconds <= 0)) ? 'Temps validé' : time}
            </span>
        )
    }

    const [driverObj, setDriverObj] = React.useState(null);
    const layout = document.querySelector('#layout');
    const startTour = (dataTour, button) => {
        setIsStartTuto(true);
        const updateTutorialState = (currentStep, totalSteps, isStart) => {
            if ((dataTour && dataTour.isStartTuto && !button) || (dataStepTuto.isStartTuto && button)) {
                let data = {
                    total: totalSteps,
                    currentStep: currentStep ?? totalSteps,
                    isStartTuto: isStart
                }
                addTutorial(data);
                setDataStepTuto(data);
            }
        };
        if (layout) layout.style.overflow = 'hidden';
        const steps = [
            {
                element: '#stepDescription',
                popover: {
                    title: 'Détails de la formation',
                    description: `Pour commencer, vous pouvez explorer les détails de la formation situés à droite de l'écran. 
                    Cette section vous fournit des informations essentielles, telles que la durée estimée de la formation, 
                    les dates de disponibilité, et le nombre de modules à compléter. Vous y trouverez également des indications 
                    sur le temps déjà passé sur le cours, ainsi que des informations sur les modalités de financement.
                    <br/><br/>
                    Cette vue d'ensemble vous permet de suivre facilement votre progression et de planifier votre apprentissage 
                    en fonction de vos disponibilités. Prenez quelques instants pour vous familiariser avec cette section avant 
                    de passer aux modules !`,
                    side: "left",
                    align: 'start'
                }
            },
            {
                element: '#stepVirtualClassroom',
                popover: {
                    title: 'Accès à votre classe virtuelle',
                    description: `Pour accéder à la classe virtuelle, attendez qu'elle soit disponible, 
                    puis cliquez sur <b>Rejoindre</b> pour entrer. Si la classe virtuelle est déjà passée, 
                    vous pouvez toujours la revoir en cliquant sur <b>Visionner</b>.`,
                    side: "right",
                    align: 'start'
                }
            },
            {
                element: '#stepModule .stepCard',
                popover: {
                    title: 'Accès aux modules',
                    description: `Pour accéder à un module, cliquez sur la <b>flèche située à droite</b> (ou cliquez directement sur le module). 
                    Cela vous permettra d'accéder le contenu du module et de commencer votre apprentissage.
                    <br/><br/>
                    Naviguez facilement entre les différents modules en suivant ces instructions simples, et 
                    progressez à votre rythme en explorant chaque section.`,
                    side: "right",
                    align: 'start'
                }
            },
            {
                element: '#stepModule .stepCardLock',
                popover: {
                    title: 'Déverrouiller le module',
                    description: `Pour déverrouiller un module, vous devrez d'abord finir le module précédent.
                    <br/><br/>
                    Vous pourrez donner une note à la fin de ce module (si le module le propose).`,
                    side: "right",
                    align: 'start'
                }
            },
            {
                element: '#stepButtonResource',
                popover: {
                    title: 'Accès aux ressources',
                    description: 'Pour visualiser les ressources disponibles pour cette formation, cliquez ici.',
                    side: "right",
                    align: 'start'
                }
            },
            {
                element: '#stepResource',
                popover: {
                    title: 'Ressources',
                    description: 'En cliquant sur l\'une des ressources, vous pourrez visualiser l\'intégralité du contenu proposé.',
                    side: "right",
                    align: 'start'
                }
            },
        ]

        const validSteps = steps.filter((step) => document.querySelector(step.element));

        const driverInstance = driver({
            showProgress: true,
            progressText: '{{current}} sur {{total}}',
            doneBtnText: 'Terminé',
            closeBtnText: 'Fermer',
            nextBtnText: 'Suivant',
            prevBtnText: 'Précédent',
            onNextClick: (element) => {
                driverInstance.moveNext();
                const { steps } = driverInstance.getConfig();
                const currentStep = driverInstance.getActiveIndex();
                if (element.id != 'stepResource') {
                    updateTutorialState(currentStep, steps.length, true);
                }
                if (element.id === 'stepButtonResource') setIsOpen(true);
            },
            onPrevClick: (element) => {
                driverInstance.movePrevious();
                if (element.id !== 'stepButtonResource') setIsOpen(false);
            },
            onCloseClick: () => {
                const { steps } = driverInstance.getConfig();
                const currentStep = driverInstance.getActiveIndex();
                updateTutorialState(currentStep, steps.length, false);
                if (layout) layout.style.overflow = 'hidden scroll';
                setIsOpen(false);
                setIsStartTuto(false);
                driverInstance.destroy();
            },
            onDestroyStarted: () => {
                const { steps } = driverInstance.getConfig();
                const currentStep = driverInstance.getActiveIndex();
                updateTutorialState(currentStep, steps.length, false);
                if (layout) layout.style.overflow = 'hidden scroll';
                setIsOpen(false);
                setIsStartTuto(false);
                driverInstance.destroy();
            },
            steps: validSteps,
        });

        if (dataTour && !button && (dataTour.currentStep === 3)) setIsOpen(true);
        driverInstance.drive((dataTour && !button) ? dataTour.currentStep : 0);
        setDriverObj(driverInstance);
    };

    React.useEffect(() => {
        if (driverObj) {
            const unblock = props.history.block(() => {
                driverObj.destroy();
                if (layout) layout.style.overflow = 'hidden scroll';
                unblock();
            });

            const NewdriverObj = new MutationObserver(() => {
                driverObj.refresh();
            });

            NewdriverObj.observe(document.body, {
                childList: true,
                subtree: true,
                attributes: true,
            });

            return unblock;
        }
    }, [driverObj]);

    const status = () => {
        return (
            <>
                {(registration.state === 1) && <span className={classes.cardState}><CheckCircleOutlineIcon className={classes.cardStateIcon} />Disponible</span>}
                {(registration.state === 2) && <span className={classes.cardState}><HourglassEmptyIcon className={classes.cardStateIcon} />Bientôt disponible</span>}
                {(registration.state === 3) && <span className={classes.cardState}><CheckCircleOutlineIcon className={classes.cardStateIcon} />Terminée</span>}
                {(registration.state === 4) && <span className={classes.cardState}><HourglassEmptyIcon className={classes.cardStateIcon} />ANDPC en attente</span>}
                {(registration.state === 5) && <span className={classes.cardState}><CheckCircleOutlineIcon className={classes.cardStateIcon} />Terminée</span>}
                {(registration.state === 6) && <span className={classes.cardState}><HourglassEmptyIcon className={classes.cardStateIcon} />Financement en attente</span>}
            </>
        )
    }
    const countdownFinishCallback = (childData) => {
        setCountdownFinish(childData);
    };

    let disabled = !(registration.state === 1 || registration.state === 3 || registration.state === 5); // TODO A voir

    React.useEffect(() => {
        if (registration && registration.formation && registration.formation.id && theme.id === 2) {
            window.dataLayer.push({
                'event': 'eec.detail',
                'duree_formation': ((registration.formation.duration / 60) / 60) + " heures",
                'format_formation': "E-learning",
                'titre_formation': registration.formation.name,
                'numero_formation': registration.formation.id,
                'type_page': "Portail"
            })
        }

    }, [registration]);

    const addTutorial = (dataStepTuto) => {
        setRegistration((prevData) => {
            let newPrevData = { ...prevData, tutorial: dataStepTuto }
            return newPrevData;
        });
        Api.post({
            route: 'learner_tutorial',
            data: {
                tutorial: {
                    registration: dataStepTuto
                }
            }
        }, (response) => {
            if (response.status === 200) {

            }
            else if (response.status === 400) {
                dispatch(
                    SnackbarOpen({
                        text: 'Erreur',
                        variant: 'error',
                    })
                );
            }
            else {
                dispatch(
                    SnackbarOpen({
                        text: 'Une erreur inattendue s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    }

    const [opacity, setOpacity] = React.useState(1);
    const scrollableRef = React.useRef(null);
    /*const updateOpacity = () => {
        const element = scrollableRef.current;

        if (element) {
            const scrollTop = element.scrollTop;
            const maxScroll = element.scrollHeight - element.clientHeight;
            if (maxScroll > 0) {
                const newOpacity = 1 - (scrollTop / maxScroll);
                setOpacity(Math.max(0, Math.min(1, newOpacity)));
            } else {
                setOpacity(0);
            }
        }
    };

    React.useEffect(() => {
        if (!loading) {
            const element = scrollableRef.current;

            if (element) {
                element.addEventListener('scroll', updateOpacity);
                window.addEventListener('resize', updateOpacity);
                return () => {
                    element.removeEventListener('scroll', updateOpacity);
                    window.removeEventListener('resize', updateOpacity);
                };
            }
        }
    }, [loading]);*/

    React.useEffect(() => {
        setIsScreen(screenReducer.size <= 1502);
        setSmallView(screenReducer.size <= 1160);
        setIsSmallDrawer(screenReducer.size >= 500)
    }, [screenReducer.size]);

    React.useEffect(() => {
        setSmallViewLoad((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);

    React.useEffect(() => {
        if (props.match.params.id) {

            dispatch(LinearProgressMainLayoutActivate());

            Api.get({
                route: 'learner_registration_get',
                params: { id: props.match.params.id }
            },
                (response) => {
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        setTotalDuration(response.data.formation.duration);
                        setRegistration(response.data);

                        // TODO a supp prochainement
                        //setSeconds(response.data.timeSpentByChapterAndSurvey)
                        setSeconds(response.data.timeSpent);
                        let dataStep = {};
                        const isTutorial = (response.data.tutorial && response.data.tutorial.length != 0) ? response.data.tutorial.isStartTuto : true;
                        (response.data.tutorial && response.data.tutorial.length != 0) ?
                            dataStep = response.data.tutorial :
                            dataStep = {
                                total: 0,
                                currentStep: 0,
                                isStartTuto: true
                            }
                        if ((response.data.state === 1 || response.data.state === 3 || response.data.state === 5) && isTutorial) {
                            startTour(dataStep, false);
                        }
                        setDataStepTuto(dataStep);
                    }
                    else if (response.status === 404) {
                        props.history.push(getRoutePathname('learner_home'));
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    }, []);

    React.useEffect(() => {
        setBreadcrumbs({
            currentPage: 'Formation',
            links: [
                { path: getRoutePathname('learner_home'), label: 'Tableau de bord' }
            ],
            img: learnerHomeFormationImageBanner + registration.formation.image,
            title: <Title isSmallView={isSmallView} isSmallDrawer={isSmallDrawer} registration={registration} />
        });
    }, [learnerHomeFormationImageBanner, registration, isSmallView, isSmallDrawer])
    return (
        <>
            {
                <ContentViewLearnerComponent loading={loading} className={classes.viewLearner} breadcrumbs={breadcrumbs} onChildrenStyle={true} {...props}>
                    <Box className={classes.mainRoot} style={isScreen ? {} : { gridTemplateColumns: 'max-content auto' }}>
                        <Box className={(isScreen && isOpen) ? classes.contnaireResourceOpenRoot : classes.contnaireResourceRoot} style={isSmallView ? { position: 'absolute', zIndex: 10, height: '100%' } : !isScreen ? { position: 'relative' } : { position: 'absolute', zIndex: 10, height: '100%' }}>
                            {(registration.resourceGroups.length > 0 && (registration.state === 1 || registration.state === 3 || registration.state === 5)) &&
                                <>
                                    <DrawerMUI
                                        variant="permanent"
                                        className={`${classes.drawer} ${isOpen ? classes.drawerOpen : classes.drawerClose}`}
                                        open={isOpen}>
                                        <Box className={classes.boxResource} >
                                            <p>Ressources</p>
                                            {!isSmallDrawer &&
                                                <Box className={classes.boxButtonOffResource} onClick={() => { setIsOpen(false) }}>
                                                    <CloseIcon />
                                                </Box>
                                            }
                                        </Box>
                                        <Box style={{ height: '100%', position: 'relative' }}>
                                            <Box style={{ width: '100%', height: '100%', overflow: 'hidden auto', position: 'absolute' }}>
                                                {registration.resourceGroups.map((resourceGroup, index) => {
                                                    if (resourceGroup.id === 133) return
                                                    return (<div key={index} id={'stepResource'} style={{ padding: '10px 20px' }}>
                                                        <p style={{ fontWeight: 700, marginBottom: 10, marginTop: 0, textAlign: 'left', fontSize: 12, color: '#5F6577' }}>{resourceGroup.name}</p>
                                                        {resourceGroup.resources.map((resource, i) => (
                                                            <div key={i}
                                                                style={disabled || (registration.state !== 5 && resourceGroup.id === 1) ?
                                                                    { color: '#bbb', fontSize: 11, lineHeight: '18px', textAlign: 'left', overflow: 'hidden', textOverflow: 'ellipsis', '-webkit-box-orient': 'vertical', '-webkit-line-clamp': 1, } :
                                                                    { color: theme.colorPrimary, fontSize: 11, lineHeight: '18px', textAlign: 'left', overflow: 'hidden', textOverflow: 'ellipsis', '-webkit-box-orient': 'vertical', '-webkit-line-clamp': 1, }}
                                                            >
                                                                <MediaLinkComponent
                                                                    style={disabled || (registration.state !== 5 && resourceGroup.id === 1) ? { color: '#bbb', fontSize: 11, lineHeight: '18px' } : { color: theme.colorPrimary, fontSize: 11, lineHeight: '18px', cursor: 'pointer' }}
                                                                    path={resource.path}
                                                                    resourceId={resource.id}
                                                                    registration={props.match.params.id}
                                                                    type={resource.type}
                                                                    history={props.history}
                                                                    disabled={disabled || (registration.state !== 5 && resourceGroup.id === 1) || isStartTuto}
                                                                >
                                                                    {'- ' + resource.name}
                                                                </MediaLinkComponent>
                                                            </div>
                                                        ))}
                                                    </div>)
                                                })}
                                            </Box>
                                        </Box>
                                    </DrawerMUI>
                                    <Box className={classes.boxButtonResource} >
                                        <p onClick={() => { setIsOpen(!isStartTuto ? !isOpen : false) }} >
                                            <span id={'stepButtonResource'} style={{ display: 'flex', padding: '8px 10px 8px 10px', alignItems: 'center' }} >
                                                <ArrowRightAltIcon style={{ transform: isOpen ? 'rotate(90deg)' : 'rotate(-90deg)' }} />
                                                Ressources
                                                <ArrowRightAltIcon style={{ transform: isOpen ? 'rotate(90deg)' : 'rotate(-90deg)' }} />
                                            </span>
                                        </p>
                                    </Box>
                                </>
                            }
                        </Box>
                        <Box className={classes.courseRoot} style={isSmallView ? { gridTemplateRows: 'auto max-content max-content', overflow: isStartTuto ? 'hidden' : 'hidden auto' } : { gridTemplateColumns: 'auto max-content max-content' }}>
                            <Box className={classes.contnaireLeftRoot} style={isStartTuto ? { overflow: 'hidden', order: isSmallView ? 2 : 'initial' } : isSmallView ? { order: 2 } : {}}>
                                <Box>
                                    {(registration.state === 1 || registration.state === 3 || registration.state === 5) ?
                                        <div id={'stepModule'} style={{ overflow: 'hidden', maxWidth: 800, margin: '30px auto 50px' }}>
                                            {
                                                /* FC endAt */
                                                registration.formation.isEppFormat &&
                                                <div style={{ margin: 10, padding: 10, border: 1, borderRadius: 20, color: '#FFF', background: theme.colorPrimary + '33', }}>
                                                    <p style={{ margin: 0, padding: 10, color: '#5F6577', fontWeight: 600 }}>
                                                        Vous avez jusqu'au {registration.fcEndAt ? moment(registration.fcEndAt).format('ll') : '-'} pour terminer le module Évaluation des pratiques professionnelles : temps 1 et la Formation continue.
                                                    </p>
                                                </div>
                                                /* FC endAt */
                                            }
                                            {(registration.hasVirtualClassroom && registration.unlockVirtualClassroom) &&
                                                Object.keys(registration.virtualClassroomsInfos).map((id, index) => (
                                                    registration.virtualClassroomsInfos[id].active &&
                                                    <Box id={'stepVirtualClassroom'} className={classes.virtualClassroom} key={index} style={{ backgroundImage: registration.formation ? 'linear-gradient(rgba(255, 255, 255, 0.78) 0%, rgba(255, 255, 255, 0.96) 70%), url(' + learnerHomeFormationImageBanner + registration.formation.image : '', }}>
                                                        <VirtualClassroomComponent virtualClassroomsInfos={registration.virtualClassroomsInfos[id]} loading={loading} {...props} />
                                                    </Box>
                                                )

                                                )
                                            }
                                            {(registration.isFinishBeforeDeploymentEpp && registration.pathPreEpp) ?
                                                <div>
                                                    <div className={classes.chapter}>EPP temps 1</div>
                                                    <div className={'stepCard'}>
                                                        <CardComponent
                                                            data={registration}
                                                            title={'EPP temps 1'}
                                                            duration={0}
                                                            timeSpent={0}
                                                            active={true}
                                                            completed={true}
                                                            type={'download'}
                                                            onClick={() => {
                                                                let isAbsolute;

                                                                try {
                                                                    let url = new URL(registration.pathPreEPP);
                                                                    isAbsolute = true;
                                                                } catch (_) {
                                                                    isAbsolute = false;
                                                                }

                                                                if (props.isLibrary) {
                                                                    dispatch(MediaShow({
                                                                        type: 1,
                                                                        path: isAbsolute ? registration.pathPreEPP : resource + 'library/pdf/' + registration.pathPreEPP
                                                                    }));
                                                                }
                                                                else {
                                                                    dispatch(MediaShow({
                                                                        type: 1,
                                                                        path: isAbsolute ? registration.pathPreEPP : resource + 'pdf/' + registration.pathPreEPP
                                                                    }));
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                :
                                                (registration.hasSurveyEpp && registration.hasQuestionEpp) &&
                                                <div>
                                                    <div className={classes.chapter}>Évaluation des pratiques professionnelles : temps 1</div>

                                                    {
                                                        (registration.inProgressFormation && !registration.isFinishPreEpp) &&
                                                        <div style={{ margin: '20px 10px 0 10px', padding: '10px', border: '1px', borderRadius: '20px', color: 'white', background: theme.colorPrimary + '33', }}>
                                                            <p style={{ margin: 0, textAlign: 'center', color: '#5F6577' }}><b>Nouvelle règlementation</b></p>
                                                            <UseAnimations animation={alertTriangle} size={80} wrapperStyle={{ margin: 'auto' }} />

                                                            <p style={{ margin: 0, padding: '10px', color: '#5F6577' }}>
                                                                À la suite de la nouvelle réglementation concernant le questionnaire des critères d’évaluation des pratiques professionnelles (EPP),
                                                                nous vous invitons à le refaire pour reprendre votre formation là où vous en étiez.
                                                            </p>
                                                            <p style={{ color: '#000' }}>
                                                                <UseAnimations animation={arrowDown} size={30} wrapperStyle={{ display: 'inline-block', verticalAlign: 'middle' }} />
                                                                Cliquez sur le lien ci-dessous
                                                                <UseAnimations animation={arrowDown} size={30} wrapperStyle={{ display: 'inline-block', verticalAlign: 'middle' }} />
                                                            </p>
                                                        </div>
                                                    }
                                                    <div className={registration.unlockSurveyPreEpp ? 'stepCard' : 'stepCardLock'}>
                                                        <CardComponent
                                                            data={registration}
                                                            title={'Audit clinique : grille d’évaluation 1'}
                                                            duration={registration.durationEpp.durationEpp1}
                                                            timeSpent={registration.durationEpp.timeSpenEpp1}
                                                            lockByTime={registration.isFinishPreEpp && registration.surveyPreEppLockByTime}
                                                            active={registration.unlockSurveyPreEpp}
                                                            completed={registration.unlockSurveyPreEpp && registration.isFinishPreEpp && (parseInt(registration.formation.financeTypeId) === 1 ? !registration.surveyPreEppLockByTime : true)}
                                                            type={'quiz'}
                                                            onClick={() => goSurveyEpp("pre")}
                                                        />
                                                    </div>
                                                </div>
                                            }

                                            {
                                                registration.hasSurveyPre &&
                                                <div>
                                                    <div className={classes.chapter}>Bilan pré-formation</div>
                                                    <div className={registration.unlockSurveyPre ? 'stepCard' : 'stepCardLock'} >
                                                        <CardComponent
                                                            data={registration}
                                                            title={'Questionnaire pré-formation'}
                                                            duration={registration.durationPrePost.durationPre}
                                                            timeSpent={registration.durationPrePost.timeSpentPre}
                                                            lockByTime={registration.isFinishPre && registration.surveyPreLockByTime}
                                                            active={registration.unlockSurveyPre}
                                                            completed={registration.unlockSurveyPre && registration.isFinishPre && (parseInt(registration.formation.financeTypeId) === 1 ? !registration.surveyPreLockByTime : true)}
                                                            type={'quiz'}
                                                            onClick={() => goSurveyPrePost("pre")}
                                                        />
                                                    </div>
                                                </div>
                                            }

                                            {registration.chapters.map((chapter, index) => (
                                                <div key={chapter.id} style={{ background: (chapter.isUnitEPP2 && !chapter.isUnitEPP2Unlock) ? '#f4f4f4' : '' }}>

                                                    {index === registration.chapters.findIndex((val) => val.isUnitEPP2 && !val.isUnitEPP2Unlock) && (
                                                        <p style={{ paddingTop: 15, fontWeight: 600, fontSize: 15 }}>
                                                            La section Évaluation des pratiques professionnelles 2 n'est pas disponible avant le {registration.eppLastStageAt}
                                                        </p>
                                                    )}

                                                    <div className={classes.chapter}>
                                                        {chapter.name}
                                                        <span style={{ color: '#5E6E82', fontSize: 13, textaAlign: 'left', width: 'max-content', display: 'block' }}>{chapter.duration ? ' | temps total : ' + Math.floor((chapter.duration) / 60) + ' min' : ''}</span>
                                                    </div>

                                                    {chapter.courses.map((course) => {
                                                        return (
                                                            <div className={(course.unlock || course.forcedUnlock) ? 'stepCard' : 'stepCardLock'}>
                                                                <CardComponent
                                                                    data={registration}
                                                                    key={course.id}
                                                                    title={course.name}
                                                                    duration={course.duration}
                                                                    timeSpent={course.timeSpentCourse}
                                                                    lockByTime={course.lockByTime}
                                                                    image={chapter.image}
                                                                    rating={course.rating}
                                                                    active={(course.unlock || course.forcedUnlock)}
                                                                    completed={course.done}
                                                                    score={course.score}
                                                                    onClick={() => goCourse(course)}
                                                                />
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            ))}


                                            {registration.resourceGroups.map((resourceGroup, index) => {
                                                if (resourceGroup.id !== 133) return
                                                return (
                                                    <div key={index} style={{ padding: '10px 0', background: theme.colorPrimary + '17', borderRadius: 10 }} >
                                                        <div className={classes.chapter}>{resourceGroup.name}</div>
                                                        {resourceGroup.resources.map((resource, i) => (
                                                            <div>
                                                                <CardResourceComponent
                                                                    data={registration}
                                                                    resource={resource}
                                                                    key={i}
                                                                    disabled={(disabled || (registration.state !== 5 && resourceGroup.id === 1))}
                                                                    {...props}
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>
                                                )
                                            })}

                                            {
                                                registration.hasSurveyPost &&
                                                <div>
                                                    <div className={classes.chapter}>Bilan post-formation</div>
                                                    <div className={registration.unlockSurveyPost ? 'stepCard' : 'stepCardLock'}>
                                                        <CardComponent
                                                            data={registration}
                                                            title={'Questionnaire post-formation'}
                                                            duration={registration.durationPrePost.durationPost}
                                                            timeSpent={registration.durationPrePost.timeSpentPost}
                                                            lockByTime={registration.isFinishPost && registration.surveyPostLockByTime}
                                                            active={registration.unlockSurveyPost}
                                                            completed={registration.unlockSurveyPost && registration.isFinishPost && (parseInt(registration.formation.financeTypeId) === 1 ? !registration.surveyPostLockByTime : true)}
                                                            type={'quiz'}
                                                            onClick={() => goSurveyPrePost("post")}
                                                        />
                                                    </div>
                                                </div>
                                            }

                                            {
                                                (registration.isFinishBeforeDeploymentEpp && registration.pathPostEpp) ?
                                                    <div>
                                                        <div className={classes.chapter}>EPP temps 2</div>
                                                        <div className={'stepCard'}>
                                                            <CardComponent
                                                                data={registration}
                                                                title={'EPP temps 2'}
                                                                duration={0}
                                                                timeSpent={0}
                                                                active={true}
                                                                completed={true}
                                                                type={'download'}
                                                                onClick={() => {
                                                                    let isAbsolute;

                                                                    try {
                                                                        let url = new URL(registration.pathPostEpp);
                                                                        isAbsolute = true;
                                                                    } catch (_) {
                                                                        isAbsolute = false;
                                                                    }

                                                                    if (props.isLibrary) {
                                                                        dispatch(MediaShow({
                                                                            type: 1,
                                                                            path: isAbsolute ? registration.pathPostEpp : resource + 'library/pdf/' + registration.pathPostEpp
                                                                        }));
                                                                    }
                                                                    else {
                                                                        dispatch(MediaShow({
                                                                            type: 1,
                                                                            path: isAbsolute ? registration.pathPostEpp : resource + 'pdf/' + registration.pathPostEpp
                                                                        }));
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    :
                                                    (registration.hasSurveyEpp && registration.hasQuestionEpp) &&
                                                    <div>
                                                        <div className={classes.chapter}>Évaluation des pratiques professionnelles : temps 2</div>
                                                        {
                                                            (!registration.unlockEppLastStage) &&
                                                            <div style={{ margin: '20px 10px 0 10px', padding: '10px', border: '1px', borderRadius: '20px', color: 'white', background: theme.colorPrimary + '33', }}>
                                                                <AccessTimeIcon style={{ margin: "auto", display: "block", fontSize: 39, color: "#5F6577" }} />
                                                                <p style={{ margin: 0, padding: '10px', color: '#5F6577' }}>
                                                                    L'accès à votre second audit clinique sera débloqué à la date du <b>{registration.eppLastStageAt}</b> pour vous laisser le temps de réajuster vos pratiques selon les recommandations personnalisées qui vous ont été transmises suite à votre premier audit. Ce délai est imposé par l'Agence nationale du DPC et conditionne le financement de votre formation.
                                                                </p>
                                                            </div>
                                                        }
                                                        <div className={registration.unlockSurveyPostEpp && registration.unlockEppLastStage ? 'stepCard' : 'stepCardLock'}>
                                                            <CardComponent
                                                                data={registration}
                                                                title={'Audit clinique : grille d’évaluation 2'}
                                                                duration={registration.durationEpp.durationEpp2}
                                                                timeSpent={registration.durationEpp.timeSpenEpp2}
                                                                lockByTime={registration.isFinishPreEpp && registration.surveyPreEppLockByTime}
                                                                type={'quiz'}
                                                                active={registration.unlockSurveyPostEpp && registration.unlockEppLastStage}
                                                                completed={registration.unlockSurveyPostEpp && registration.unlockEppLastStage && registration.isFinishPostEpp && (parseInt(registration.formation.financeTypeId) === 1 ? !registration.surveyPreEppLockByTime : true)}
                                                                onClick={() => goSurveyEpp("post")}
                                                            />
                                                        </div>
                                                    </div>
                                            }
                                            {
                                                registration.pathPostEPP &&
                                                <div>
                                                    <div className={classes.chapter}>Questionnaire de satisfaction</div>
                                                    <div className={registration.unlockSurveySatisfaction ? 'stepCard' : 'stepCardLock'}>
                                                        <CardComponent
                                                            data={registration}
                                                            title={'Questionnaire de satisfaction'}
                                                            duration={0}
                                                            timeSpent={0}
                                                            active={registration.unlockSurveySatisfaction}
                                                            completed={registration.unlockSurveySatisfaction && registration.isFinishSurveySatisfaction}
                                                            type={'quiz'}
                                                            onClick={() => { goSurveySatisfaction() }}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            {
                                                registration.pathHonorCertificate &&
                                                <div>
                                                    <div className={classes.chapter}>Attestation sur l'honneur</div>
                                                    <div className={'stepCard'}>
                                                        <CardComponent
                                                            data={registration}
                                                            title={'Attestation sur l\'honneur'}
                                                            duration={0}
                                                            timeSpent={0}
                                                            active={true}
                                                            completed={true}
                                                            type={'download'}
                                                            onClick={() => {
                                                                let isAbsolute;

                                                                try {
                                                                    let url = new URL(registration.pathHonorCertificate);
                                                                    isAbsolute = true;
                                                                } catch (_) {
                                                                    isAbsolute = false;
                                                                }

                                                                if (props.isLibrary) {
                                                                    dispatch(MediaShow({
                                                                        type: 1,
                                                                        path: isAbsolute ? registration.pathHonorCertificate : resource + 'library/pdf/' + registration.pathHonorCertificate
                                                                    }));
                                                                }
                                                                else {
                                                                    dispatch(MediaShow({
                                                                        type: 1,
                                                                        path: isAbsolute ? registration.pathHonorCertificate : resource + 'pdf/' + registration.pathHonorCertificate
                                                                    }));
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            {
                                                registration.hasSurveySatisfaction &&
                                                <div>
                                                    <div className={classes.chapter}>Questionnaire de satisfaction</div>
                                                    <div className={registration.unlockSurveySatisfaction ? 'stepCard' : 'stepCardLock'}>
                                                        <CardComponent
                                                            data={registration}
                                                            title={'Questionnaire de satisfaction'}
                                                            duration={0}
                                                            timeSpent={0}
                                                            active={registration.unlockSurveySatisfaction}
                                                            completed={registration.unlockSurveySatisfaction && registration.isFinishSurveySatisfaction}
                                                            type={'quiz'}
                                                            onClick={() => { goSurveySatisfaction() }}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        :
                                        <div style={{ maxWidth: 1000, margin: 'auto', padding: '30px 50px', color: '#5F6577' }}>
                                            {registration.state === 4 ?
                                                <div style={{ fontSize: 14, textAlign: 'left' }}>
                                                    <h3>Finalisez votre inscription</h3>
                                                    <p>Votre inscription à la formation <span className={classes.strongText}>{registration.formation.name}</span> d’une durée de {registration.formation.duration / 3600} heures à bien été prise en compte mais n’est pas encore finalisée.</p>
                                                    <p>Afin de la compléter, veuillez suivre les étapes suivantes : </p>
                                                    <ol>
                                                        <li>Rendez-vous sur: <a style={{ color: theme.colorPrimary }} href='https://www.agencedpc.fr/professionnel/login' target="_blank">https://www.agencedpc.fr/professionnel/login</a>, connectez-vous ou créez un compte.</li>
                                                        <li>Cliquez sur la rubrique « Inscription »</li>
                                                        <li>Dans le menu à gauche, cliquez sur « Rechercher actions »</li>
                                                        <li>Dans le champ « Référence de l’actions » copiez/collez le code suivant :
                                                            <span className={classes.strongText}>{registration.formation.codeANDPC}</span>
                                                            <Tooltip title="Copier le code">
                                                                <IconButton aria-label="copy" size="small"
                                                                    onClick={() => {
                                                                        navigator.clipboard.writeText(registration.formation.codeANDPC);
                                                                        dispatch(
                                                                            SnackbarOpen({
                                                                                text: 'Code copié dans le presse papier',
                                                                                variant: 'success',
                                                                            })
                                                                        );
                                                                    }}>
                                                                    <FileCopyIcon fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </li>
                                                        <li>Cliquez sur « Détail action de DPC » puis sélectionnez une session et finalisez votre inscription.</li>
                                                    </ol>
                                                    <p className={classes.strongText}>{theme.name} vous remercie pour votre confiance et vous souhaite une excellente formation.<br />Notre équipe reste à votre disposition pour tout renseignement complémentaire. </p>
                                                </div>
                                                : registration.state === 7 ?
                                                    <div style={{ margin: '20px 10px 0 10px', padding: '10px', border: '1px', borderRadius: '20px', color: 'white', background: '#ff9800cc' }}>
                                                        <p style={{ margin: 0, padding: '10px', color: '#5E6E82', fontWeight: 700 }}>
                                                            Nous rencontrons quelques difficultés avec votre paiement. Veuillez contacter notre équipe pour résoudre le problème. <br /><br />Cordialement, L'équipe {theme.name}
                                                        </p>
                                                    </div>
                                                    :
                                                    <p className={classes.strongText}>Nous vous remercions d’avoir choisi cette formation, vous serez très prochainement recontacté(e) par notre équipe afin de finaliser votre inscription. </p>

                                            }
                                            <br />
                                            <div style={{ fontSize: 14, textAlign: 'left' }}>
                                                <h3>Nous contacter </h3>
                                                <span>Par email : <a style={{ color: theme.colorPrimary }} href="mailto:contact@santeformapro.com">contact@santeformapro.com</a></span>
                                                <br />
                                                <span>Par téléphone : 04 42 17 90 82  </span>
                                            </div>
                                        </div>
                                    }
                                </Box>
                                <Footer pageRegistration={true} {...props} />
                            </Box>
                            <Box style={{ position: 'relative', zIndex: 3 }}>
                                {((registration.state === 1 || registration.state === 3 || registration.state === 5) && !isStartTuto) &&
                                    <Button className={isSmallView ? classes.mobilbuttonTuto : classes.buttonTuto} style={{ background: '#5E6E82' }} onClick={() => { startTour(null, true); setIsOpen(false); }} >
                                        <p><span>Lancer le didacticiel</span></p> <LiveHelpIcon style={{ fontSize: 17 }} />
                                    </Button>
                                }
                            </Box>
                            <Box className={classes.contnaireRightRoot} style={isSmallView ? { overflow: 'initial', width: '100%', height: 'auto', order: 1, borderLeft: 0 } : {}} id={'stepDescription'}>
                                <Box>
                                    <Box className={classes.boxDuration} >
                                        <p style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', margin: 'auto', color: '#5F6577', justifyContent: 'center' }}>
                                            <AccessTimeIcon className={classes.cardStateIcon} />
                                            Durée estimée&ensp;<b>{registration.formation.duration / 3600} heures</b>
                                        </p>
                                        <p className='timer-root'>Temps passé | <TimeSpent seconds={seconds} setSeconds={setSeconds} /></p>
                                        {/*((registration.state === 1 || registration.state === 3 || registration.state === 5) && (parseInt(registration.formation.financeTypeId) !== 6))
                                            && stayTime(totalDuration, registration.timeSpentByChapterAndSurvey, 2)
                                        */}
                                        <p style={{ margin: 'auto', color: '#5F6577', textAlign: 'center' }}>
                                            <span style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center' }}>Financement</span>
                                            <b style={{ fontSize: 14 }}>{registration.formation.financeType}</b>
                                        </p>
                                    </Box>
                                    <Box className={classes.boxStep} >
                                        {(registration.state === 1 || registration.state === 3 || registration.state === 5) &&
                                            <>
                                                <p className='step-root'>Modules terminés : {registration.courseDoneCount} sur {registration.courseCount}</p>
                                                <LinearProgress className={classes.LinearProgressRoot} variant="determinate" value={registration.courseDoneCount * 100 / registration.courseCount} />
                                            </>
                                        }
                                    </Box>
                                    <Box className={classes.boxSatuts} >
                                        {status()}
                                        <span style={{ display: 'flex', alignItems: 'center', color: '#5F6577' }}><DateRangeIcon className={classes.cardStateIcon} />{(registration.startAt && registration.endAt) ? `${moment(registration.startAt).format('ll')} • ${moment(registration.endAt).format('ll')}` : 'Aucune durée'}</span>
                                    </Box>
                                </Box>
                                <div ref={scrollableRef} style={{ height: '100%', overflow: 'hidden auto', padding: ' 0 37px', color: '#5F6577', position: 'relative' }}>
                                    {(registration.state === 1 || registration.state === 3 || registration.state === 5) ?
                                        <>
                                            {!(registration.formation.presentationVideoLink || (registration.formation.financeType === 'ANDPC') || registration.formation.objective || registration.formation.description) ?
                                                <div style={{ width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', opacity: 0.5 }}>
                                                    <NotesIcon style={{ fontSize: 50 }} /><p style={{ margin: 0, fontSize: 21, fontWeight: 'bold', textAlign: 'center' }}>Aucune information <br />supplémentaire.</p>
                                                </div> :
                                                <Box style={{ padding: '0 0 30px 0' }}>
                                                    {registration.formation.presentationVideoLink && (
                                                        <div style={{
                                                            margin: isSmallView ? 'auto' : '20px auto',
                                                            padding: '20px',
                                                            textAlign: 'center',
                                                            border: '2px solid #E6E9F0',
                                                            borderRadius: '10px',
                                                            backgroundColor: '#f9f9f9'
                                                        }}>
                                                            {registration.formation.presentationVideoLink.startsWith('http') ?
                                                                <video
                                                                    style={{
                                                                        border: 'none',
                                                                        borderRadius: '8px',
                                                                        width: '100%',
                                                                        height: isSmallView ? 'calc(40vh - 100px)' : 'calc(30vh - 100px)',
                                                                    }}
                                                                    src={`${registration.formation.presentationVideoLink}?autoplay=0`}
                                                                    controls
                                                                    preload="metadata"
                                                                    muted
                                                                />
                                                                :
                                                                <iframe
                                                                    style={{
                                                                        border: 'none',
                                                                        borderRadius: '8px',
                                                                        width: '100%',
                                                                        height: isSmallView ? 'calc(40vh - 100px)' : 'calc(30vh - 100px)'
                                                                    }}
                                                                    src={`https://www.youtube.com/embed/${registration.formation.presentationVideoLink}?autoplay=0`}
                                                                    allowFullScreen
                                                                    allow="autoplay; encrypted-media"
                                                                />}
                                                        </div>
                                                    )}
                                                    {registration.formation.description &&
                                                        <div style={{ textAlign: 'left', fontSize: 13 }}>
                                                            <h2 style={{ marginTop: 10 }}>Introduction</h2>
                                                            <div className={classes.noMargin} dangerouslySetInnerHTML={{ __html: registration.formation.description }} />
                                                        </div>}
                                                    {registration.formation.objective &&
                                                        <div style={{ textAlign: 'left', fontSize: 13 }}>
                                                            <h2 style={{ marginTop: 10 }}>Objectifs pédagogiques</h2>
                                                            <div className={classes.noMargin} dangerouslySetInnerHTML={{ __html: registration.formation.objective }} />
                                                        </div>}

                                                    {registration.formation.financeType === 'ANDPC' &&
                                                        <div style={{ textAlign: 'left', fontSize: 13 }}>
                                                            <h2 style={{ marginTop: 10 }}>Instructions</h2>
                                                            <p style={{ margin: '10px 0' }}>Vous devez obtenir un résultat supérieur à 75 % pour avoir accès au prochain cours.</p>
                                                        </div>}
                                                </Box>
                                            }
                                            {/*<Box className={`${isSmallView ? classes.loadingTextSmallView : classes.loadingTextView}`} style={isSmallView ? {} : { opacity: opacity }}></Box>*/}
                                        </> : <div style={{ width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', opacity: 0.5 }}>
                                            <HourglassEmptyIcon style={{ fontSize: 50 }} /><p style={{ margin: 0, fontSize: 21, fontWeight: 'bold' }}>En attente de finalisation.</p>
                                        </div>
                                    }
                                </div>
                            </Box>
                        </Box>
                    </Box>
                </ContentViewLearnerComponent>
            }
        </>
    );
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Zoom ref={ref} {...props} />
});

const TimeSpent = (props) => {
    const hours = Math.floor(props.seconds / 3600);
    const minutes = Math.floor((props.seconds % 3600) / 60);
    const remainingSeconds = props.seconds % 60;

    React.useEffect(() => {
        const interval = setInterval(() => {
            props.setSeconds(seconds => seconds + 1);
        }, 1000);
        return () => clearInterval(interval);
    }, [])

    return <>{`${hours} heures ${minutes}m ${remainingSeconds}s`}</>
}

const drawerOpenWidth = 340;
const drawerCloseWidth = 0;

const useStyles = (theme, isSmallDrawer) => makeStyles(style => ({
    viewLearner: {
        '& .ContentSvg-root': {
            display: 'none'
        },
    },
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        transform: 'translate(-50%,-50%)',
        position: 'absolute',
        top: '40%',
        left: '50%',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div':
        {
            margin: 'auto',
            width: '15% !important'
        }
    },
    dialog: {
        '& .MuiDialog-paper': {
            maxWidth: 450
        },
        '& .MuiDialog-paper, .MuiDialogContent-root': {
            overflow: 'initial',
            textAlign: 'center',
            padding: 15
        },
        '& .MuiDialogContent-root:first-child': {
            paddingTop: 'initial'
        }
    },
    buttonTuto: {
        position: 'absolute',
        bottom: 9,
        right: 15,
        width: 'max-content',
        color: '#FFF',
        opacity: .5,
        fontSize: 11,
        minWidth: 'initial',
        transition: 'all .4s',
        '& p': {
            width: 0,
            overflow: 'hidden',
            margin: 0,
            transition: 'all .4s',
            '& span': {
                width: 'max-content',
                display: 'block',
                color: '#FFF'
            }
        },
        '&:hover': {
            opacity: '1 !important',
            '& p': {
                width: '133px',
            }
        }
    },

    mobilbuttonTuto: {
        position: 'fixed',
        bottom: 30,
        right: 15,
        width: 'max-content',
        color: '#FFF',
        opacity: .5,
        fontSize: 11,
        minWidth: 'initial',
        zIndex: 1,
        transition: 'all .4s',
        '& p': {
            width: 0,
            overflow: 'hidden',
            margin: 0,
            transition: 'all .4s',
            '& span': {
                width: 'max-content',
                display: 'block',
                color: '#FFF'
            }
        },
        '&:hover': {
            opacity: '1 !important',
            '& p': {
                width: '133px',
            }
        }
    },

    drawer: {
        width: isSmallDrawer ? drawerOpenWidth : '100vw',
        height: '100%',
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxShadow: '-1px 0px 2px rgba(0,0,0,0.2)',
        background: '#FFFFFF',
        overflowX: 'hidden',
        zIndex: 1,
        boxSizing: 'border-box',
        '& .MuiDrawer-paperAnchorDockedLeft': {
            border: 0
        },
        '& .MuiDrawer-paper': {
            height: '100%',
            boxSizing: 'border-box',
            width: '100%',
            overflowX: 'hidden',
            position: 'relative',
            borderRight: isSmallDrawer ? 'solid #F6F6F6 5px' : 'none'
        }
    },
    drawerOpen: {
        transition: style.transitions.create('width', {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.enteringScreen,
        }),
        width: isSmallDrawer ? drawerOpenWidth : '100vw'
    },
    drawerClose: {
        transition: style.transitions.create('width', {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.leavingScreen,
        }),
        width: drawerCloseWidth
    },
    timerRoot: {
        fontWeight: 'bold',
        color: '#FFF',
        padding: '3px 10px',
        borderRadius: 6,
        fontSize: 11,
        margin: '10px auto auto',
        display: 'flex',
        gap: 5,
        alignItems: 'center',
        flexWrap: 'wrap',
        width: 'fit-content'
    },
    headerRoot: {
        textAlign: 'center',
        margin: 'auto',
        position: 'relative',
        zIndex: 2,
    },
    titleRoot: {
        fontSize: 30,
        margin: 'auto',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        height: 'max-content',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        padding: '0 30px',
    },
    mobilTitleRoot: {
        fontSize: '15px !important',
        margin: 'auto',
        padding: '0 30px',
    },
    boxStep: {
        display: 'flex',
        flexDirection: 'column',
        zIndex: 2,
        position: 'relative',
        alignItems: 'center',
        width: '100%',
        margin: 'auto',
        '& p.step-root': {
            width: '100%',
            padding: '10px 10px',
            textAlign: 'center',
            fontSize: 14,
            display: 'block',
            fontWeight: 550,
            background: theme.colorPrimary + '33',
            color: '#5F6577',
            margin: 0
        },
    },
    LinearProgressRoot: {
        width: '100%',
        background: theme.colorPrimary + '33',
        '& .MuiLinearProgress-barColorPrimary': {
            background: theme.colorPrimary,
        }
    },
    cardState: {
        color: '#5F6577',
        display: 'flex',
        alignItems: 'center',
    },
    cardStateText: {
        fontWeight: 700,
        padding: '3px 5px',
        fontSize: 14,
        display: 'initial',
    },
    cardStateIcon: {
        fontSize: 22,
        borderRadius: 20,
        marginRight: 5
    },
    mainRoot: {
        height: '100%',
        position: 'relative',
        display: 'grid',
        overflow: 'hidden',
        zIndex: 2
    },
    courseRoot: {
        height: '100%',
        position: 'relative',
        display: 'grid',
        overflow: 'hidden',
    },
    contnaireRightRoot: {
        backgroundColor: '#FFFFFF',
        borderLeft: 'solid #F6F6F6 5px',
        width: 440,
        display: 'grid',
        gridTemplateRows: 'max-content auto',
        height: '100%',
        overflow: 'hidden',
        position: 'relative'
    },
    boxDuration: {
        padding: '20px 0',
        display: 'flex',
        flexDirection: 'column',
        '& p.timer-root': {
            width: 'fit-content',
            margin: '4px auto',
            padding: '4px 10px',
            textAlign: 'center',
            fontSize: 13,
            display: 'block',
            fontWeight: 550,
            background: theme.colorPrimary,
            color: '#FFF',
            borderRadius: 5,
        },
    },
    link: {
        cursor: 'pointer',
        fontSize: 13,
        textAlign: 'left',
        '&:hover': {
            opacity: 0.6
        }
    },
    boxSatuts: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        padding: '14px 0',
        background: '#F6F6F6',
        justifyContent: 'center',
        fontSize: 13,
        fontWeight: 500,
        gap: 22
    },
    contnaireResourceRoot: {
        '&::before': {
            background: '#000',
            content: "' '",
            position: 'absolute',
            width: '100vw',
            height: '100%',
            visibility: 'hidden',
            transition: 'all .4s',
            opacity: 0
        }
    },
    contnaireResourceOpenRoot: {
        '&::before': {
            background: '#000',
            content: "' '",
            position: 'absolute',
            width: '100vw',
            height: '100%',
            visibility: 'visible',
            transition: 'all .4s',
            opacity: 0.3,
        }
    },
    boxResource: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        padding: '14px 0',
        background: '#F6F6F6',
        justifyContent: 'center',
        fontWeight: 500,
        position: 'relative',
        gap: 22,
        '& p, .driver-active-element': {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            margin: 'auto',
            color: 'rgb(95, 101, 119)',
            justifyContent: 'center',
            fontSize: 15,
            fontWeight: 'bold'
        }
    },
    contnaireLeftRoot: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden auto',
        position: 'relative'
    },
    boxButtonOffResource: {
        width: 'fit-content',
        zIndex: 3,
        position: 'absolute',
        background: theme.colorPrimary,
        right: '4%',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        borderRadius: 4,
        height: 'fit-content',
        padding: '3px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#FFF',
        cursor: 'pointer'
    },
    boxButtonResource: {
        background: 'rgb(198, 224, 229)',
        width: 0,
        zIndex: 3,
        position: 'absolute',
        transform: 'translate(-0%,-50%)',
        top: '50%',
        right: 0,
        '& p': {
            color: '#FFF',
            margin: 0,
            fontSize: 15,
            textAlign: 'center',
            fontWeight: 'bolder',
            transform: 'rotate(90deg) translate(-13%,150%)',
            background: theme.colorPrimary,
            width: 'max-content',
            position: 'absolute',
            cursor: 'pointer',
            top: '50%',
            left: 0,
            borderRadius: '10px 10px 0 0'
        }
    },
    styleDriver: {
        color: '#5F6577'
    },
    chapter: {
        color: theme.colorPrimary,
        padding: '8px 0 0 0',
        fontSize: 20,
        paddingLeft: 15,
        textAlign: 'left',
        fontWeight: 550,
        display: 'flex',
        alignItems: 'center',
        gap: 3,
        flexWrap: 'wrap'
    },
    virtualClassroom: {
        margin: '30px 20px',
        padding: 22,
        borderRadius: 14,
        color: 'rgb(95, 101, 119)',
        textAlign: 'center',
        border: '2px solid #E6E9F0',
        '& div': {
            color: 'rgb(95, 101, 119) !important',
        }
    },
    virtualClassroomIntro: {
        margin: 0,
        fontWeight: 'bold'
    },
    buttonGroup: {
        minHeight: 35,
        padding: '20px 0px 5px'
    },
    noMargin: {
        '& p,h1,h2,h3': {
            margin: 0
        }
    },
    loadingTextView: {
        position: 'sticky',
        width: '100%',
        height: 100,
        margin: -100,
        bottom: 0,
        background: 'linear-gradient(rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.96) 70%)',
        left: 0,
        overflow: 'hidden'
    },
    loadingTextSmallView: {
        width: '100%',
        height: 0,
        margin: 0,
        display: 'none',
        overflow: 'hidden'
    },
}));

export default Registration;